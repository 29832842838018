import { call, put, takeLatest } from 'redux-saga/effects';

import { IOrdersResponseDto } from '@services/aftership.dto';
import aftershipService from '@services/aftership.service';

import { actions } from '@store/actions/aftership/order';
import { Action, ActionType } from '@store/interfaces/aftership/order';

function* getOrder(payload: Action) {
  try {
    const { id, force } = payload;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const dataResponse: IOrdersResponseDto = yield call(aftershipService.fetchOrder, String(id), force);
    yield put(actions.getOrderSuccess(dataResponse));
    yield put(actions.clearMessageError());
  } catch (error: any) {
    console.log(error);
    const message = error?.response?.data?.message || error?.message;
    yield put(actions.getOrderFailure(message));
  }
}
function* orderSaga() {
  yield takeLatest(ActionType.GET_ORDER_REQUEST, getOrder);
}
export default orderSaga;
