import { call, delay, put, takeLatest } from 'redux-saga/effects';

import { IOrdersResponseDto } from '@services/aftership.dto';
import aftershipService from '@services/aftership.service';

import { actions } from '@store/actions/aftership/orders';
import { Action, ActionType } from '@store/interfaces/aftership/orders';

function* getOrders({ payload }: Action) {
  yield delay(1000);
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const dataResponse: IOrdersResponseDto = yield call(aftershipService.fetchOrders, payload);
    yield put(actions.getOrdersSuccess(dataResponse));
    yield put(actions.clearMessageError());
  } catch (error: any) {
    yield put(actions.getOrdersFailure(error?.message));
  }
}
// function* watchGetOrders() {
//   console.log('watch order request');
//   yield takeLatest(ActionType.GET_ORDERS_REQUEST, getOrders);
// }

function* ordersSaga() {
  yield takeLatest(ActionType.GET_ORDERS_REQUEST, getOrders);
}
export default ordersSaga;
