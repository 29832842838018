export enum ActionType {
  GET_ORDER_REQUEST = 'aftership/orders/GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS = 'aftership/orders/GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE = 'aftership/orders/GET_ORDER_FAILURE',
  CLEAR_ORDER_STATE = 'aftership/orders/CLEAR_ORDER_STATE',
  CLEAR_MESSAGE_ERROR = 'aftership/orders/CLEAR_MESSAGE_ERROR',
}

export type Action = any;
//   | FSA<ActionType.TOKEN_REQUEST>
//   | FSA<ActionType.TOKEN_REQUEST_SUCCESS>
//   | FSA<ActionType.TOKEN_REQUEST_FAILURE>
//   | FSA<ActionType.LOGIN_REQUEST>
//   | FSA<ActionType.LOGIN_SUCCESS>
//   | FSA<ActionType.LOGIN_FAILURE>
//   | FSA<ActionType.LOGOUT>
