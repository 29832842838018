import { IOrdersState } from '@services/aftership.dto';

import { Action, ActionType } from '@store/interfaces/aftership/orders';

const initialState: IOrdersState = { loading: true };

export default function orders(state = initialState, action: Action) {
  // const { payload } = action;
  switch (action.type) {
    case ActionType.GET_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionType.GET_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
        message: action?.message,
      };
    case ActionType.GET_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.error,
      };
    case ActionType.CLEAR_MESSAGE_ERROR:
      return {
        ...state,
        message: undefined,
        error: undefined,
      };
    case ActionType.CLEAR_ORDERS_STATE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
