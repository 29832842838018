import { IFilterOrdersDto, IOrdersResponseDto } from '@services/aftership.dto';

import { Action, ActionType } from '@store/interfaces/aftership/orders';

const getOrders = (payload: IFilterOrdersDto): Action => {
  return { type: ActionType.GET_ORDERS_REQUEST, payload };
};
const getOrdersSuccess = (payload: IOrdersResponseDto): Action => {
  return { type: ActionType.GET_ORDERS_SUCCESS, payload };
};
const getOrdersFailure = (error?: any): Action => {
  return { type: ActionType.GET_ORDERS_FAILURE, error };
};
const clearOrdersState = (): Action => {
  return { type: ActionType.CLEAR_ORDERS_STATE };
};

const clearMessageOrdersError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ORDERS_ERROR };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getOrders,
  getOrdersSuccess,
  getOrdersFailure,
  clearOrdersState,
  clearMessageOrdersError,

  clearMessageError,
};

const orders = {
  actions,
};

export default orders;
