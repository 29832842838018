import { IOrdersResponseDto } from '@services/aftership.dto';

import { Action, ActionType } from '@store/interfaces/aftership/order';

const getOrder = (id: string | number, force = false): Action => {
  return { type: ActionType.GET_ORDER_REQUEST, id, force };
};
const getOrderSuccess = (payload: IOrdersResponseDto): Action => {
  return { type: ActionType.GET_ORDER_SUCCESS, payload };
};
const getOrderFailure = (error?: any): Action => {
  return { type: ActionType.GET_ORDER_FAILURE, error };
};
const clearOrderState = (): Action => {
  return { type: ActionType.CLEAR_ORDER_STATE };
};

const clearMessageError = (): Action => {
  return { type: ActionType.CLEAR_MESSAGE_ERROR };
};

export const actions = {
  getOrder,
  getOrderSuccess,
  getOrderFailure,
  clearOrderState,
  clearMessageError,
};

const order = {
  actions,
};

export default order;
