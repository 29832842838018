import formatDate from 'date-fns/format';
import subDate from 'date-fns/sub';
import qs from 'qs';

import { ObjectInterface } from '@interfaces';

import { BASE_API_ENDPOINT } from '@constants';
import { saveBlobFile } from '@helpers/file';
import { buildUrl } from '@helpers/url';

import { IFilterOrdersDto } from './aftership.dto';
import { ApiClient, subscribeAuthorizationHeader } from './api';
import { withRefreshTokenAndRetry } from './auth.service';

function applyDefaultFilterParam(params: any) {
  if (!params.fromCreatedAt) {
    params['fromCreatedAt'] = formatDate(subDate(new Date(), { days: 30 }), 'yyyy/MM/dd');
  }
  if (!params.toCreatedAt) {
    params['toCreatedAt'] = formatDate(new Date(), 'yyyy/MM/dd');
  }
  return params;
}

function transformApiData(response: any) {
  if (response.data.statusCode != '200') {
    throw new Error((response.message as string) || 'Request Failure');
  }
  return response.data.data;
}

export const api = new ApiClient(BASE_API_ENDPOINT, { 'User-Agent': process.env.REACT_APP_API_USER_AGENT ?? 'glamira-backoffice' });

api.subscribe((client) => {
  subscribeAuthorizationHeader(client, 'aftership');
  withRefreshTokenAndRetry(client);
});

class AftershipService {
  fetchOrders(payload: IFilterOrdersDto) {
    const param: any = { ...payload };
    if (payload.trackingNumberOrIncrementId) {
      param.trackingNumberOrIncrementId = payload.trackingNumberOrIncrementId;
    }
    const query = qs.stringify(param);
    const url = `/shipments${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  exportOrders(payload: IFilterOrdersDto) {
    return this.exportShipments(payload);
  }

  exportShipments(payload: IFilterOrdersDto) {
    const param: any = { ...payload };
    delete param?.searchCriteria?.currentPage;

    if (!param.searchCriteria) {
      param.searchCriteria = { pageSize: 0, currentPage: 1 };
    }

    param.searchCriteria.currentPage = 1;
    param.searchCriteria.pageSize = 0;

    if (payload.trackingNumberOrIncrementId) {
      param.trackingNumberOrIncrementId = payload.trackingNumberOrIncrementId;
    }
    const query = qs.stringify(param);
    const url = `/shipments/export${query ? '?' : ''}${query}`;
    return api
      .get(url, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': 'attachment;filename=data.csv',
        },
      })
      .then((response) => {
        saveBlobFile(response.data, 'application/vnd.ms-excel', 'shipment.csv');
      });
  }

  fetchOrder(id: string | number, force = false) {
    const query: ObjectInterface = {};
    if (force) {
      query.force = 1;
    }
    const url = buildUrl(`/shipments/detail/:id`, { id }, query);
    return api.get(url).then(transformApiData);
  }

  async updateShipment(id: string | number, attrs: ObjectInterface) {
    const url = buildUrl(`/shipments/detail/:id`, { id });
    return api.put(url, attrs).then(transformApiData);
  }

  statsByStatusCode(from?: string, to?: string) {
    const param: any = {
      fromCreatedAt: from,
      toCreatedAt: to,
    };
    const query = qs.stringify(applyDefaultFilterParam(param));
    const url = `/shipment/stats/count-by-status-code${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  statsTotalShipmentsOvertime(from?: string, to?: string) {
    const param: any = {
      fromCreatedAt: from,
      toCreatedAt: to,
    };
    const query = qs.stringify(applyDefaultFilterParam(param));
    const url = `/shipment/stats/total-shipments-overtime${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  statsTotalExceptionOvertime(from?: string, to?: string) {
    const param: any = {
      fromCreatedAt: from,
      toCreatedAt: to,
    };
    const query = qs.stringify(applyDefaultFilterParam(param));
    const url = `/shipment/stats/total-exceptions-overtime${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  statsTopCarrier(from?: string, to?: string) {
    const param: any = {
      fromCreatedAt: from,
      toCreatedAt: to,
    };
    const query = qs.stringify(applyDefaultFilterParam(param));
    const url = `/shipment/stats/top-carrier${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }
  statsTopDestination(from?: string, to?: string) {
    const param: any = {
      fromCreatedAt: from,
      toCreatedAt: to,
    };
    const query = qs.stringify(applyDefaultFilterParam(param));
    const url = `/shipment/stats/top-destination${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }
  statsExceptionsByCarrier(from?: string, to?: string) {
    const param: any = {
      fromCreatedAt: from,
      toCreatedAt: to,
    };
    const query = qs.stringify(applyDefaultFilterParam(param));
    const url = `/shipment/stats/exceptions-by-carrier${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  statsExceptionsByDestination(from?: string, to?: string) {
    const param: any = {
      fromCreatedAt: from,
      toCreatedAt: to,
    };
    const query = qs.stringify(applyDefaultFilterParam(param));
    const url = `/shipment/stats/count-exception-by-destination${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  statsConnection() {
    const url = '/healthy';
    return api.get(url).then(transformApiData);
  }

  notificationSetting(type = 'customer') {
    const url = `/setting/notify-${type}`;
    return api.get(url).then(transformApiData);
  }

  updateSetting(id: number | string, value: number | string | boolean) {
    const url = `/setting/${id}`;
    return api.put(url, { value }).then(transformApiData);
  }
}
const aftershipService = new AftershipService();
export default aftershipService;
