import CarrierData from './options/carriers.json';
import CountriesData from './options/countries.json';
import Purposes from './options/purposes.json';

export enum TrackStatus {
  INFORMATION_RECEIVED = 1,

  IN_TRANSIT = 2,
  ACCEPTANCE_SCAN = 201,
  ARRIVAL_SCAN = 202,
  ARRIVED_AT_DESTINATION_COUNTRY = 203,
  CUSTOMS_CLEARANCE_COMPLETED = 204,
  CUSTOMS_CLEARANCE_STARTED = 205,
  DEPARTURE_SCAN = 206,
  PROBLEM_RESOLVED = 207,
  FORWARDED_TO_A_DIFFERENT_DELIVERY = 208,

  OUT_FOR_DELIVERY = 3,
  CUSTOMER_CONTACTED = 301,
  DELIVERY_APPOINTMENT_SCHEDULE = 302,

  FAILED_ATTEMPT = 4,
  ADDRESS_SEE_NOT_AVAILABLE = 401,
  BUSINESS_CLOSED = 402,

  DELIVERED = 5,
  PICKED_UP_BY_CUSTOMER = 501,
  SIGN_BY_CUSTOMER = 502,
  DELIVERED_AND_RECEIVED_CASH_ON_DELIVERY = 503,

  AVAILABLE_FOR_PICKUP = 6,

  EXCEPTION = 7,
  CUSTOMER_MOVED = 701,
  CUSTOMER_REFUSED_DELIVERY = 702,
  DELAYED_CUSTOMS_CLEARANCE = 703,
  DELAYED_EXTERNAL_FACTORS = 704,
  HELD_FOR_PAYMENT = 705,
  INCORRECT_ADDRESS = 706,
  PICK_UP_MISSED = 707,
  REJECTED_BY_CARRIER = 708,
  RETURNING_TO_SENDER = 709,
  RETURNED_TO_SENDER = 710,
  SHIPMENT_DAMAGED = 711,
  SHIPMENT_LOST = 712,

  EXPIRED = 8,

  PENDING = 9,
  OTHER = 901,
  CARRIER_ACCOUNT_NOT_CONNECTED = 902,
}

export enum TrackStatusMagento {
  DELIVERED = 2,
  RETURNED_TO_SENDER = 710,
  SHIPMENT_LOST = 712,
}

export const TrackStatusChild = {
  [TrackStatus.INFORMATION_RECEIVED]: [TrackStatus.INFORMATION_RECEIVED],
  [TrackStatus.IN_TRANSIT]: [
    TrackStatus.IN_TRANSIT,
    TrackStatus.ACCEPTANCE_SCAN,
    TrackStatus.ARRIVAL_SCAN,
    TrackStatus.ARRIVED_AT_DESTINATION_COUNTRY,
    TrackStatus.CUSTOMS_CLEARANCE_COMPLETED,
    TrackStatus.CUSTOMS_CLEARANCE_STARTED,
    TrackStatus.DEPARTURE_SCAN,
    TrackStatus.PROBLEM_RESOLVED,
    TrackStatus.FORWARDED_TO_A_DIFFERENT_DELIVERY,
  ],
  [TrackStatus.OUT_FOR_DELIVERY]: [TrackStatus.OUT_FOR_DELIVERY, TrackStatus.CUSTOMER_CONTACTED, TrackStatus.DELIVERY_APPOINTMENT_SCHEDULE],
  [TrackStatus.FAILED_ATTEMPT]: [TrackStatus.FAILED_ATTEMPT, TrackStatus.ADDRESS_SEE_NOT_AVAILABLE, TrackStatus.BUSINESS_CLOSED],
  [TrackStatus.DELIVERED]: [
    TrackStatus.DELIVERED,
    TrackStatus.PICKED_UP_BY_CUSTOMER,
    TrackStatus.SIGN_BY_CUSTOMER,
    TrackStatus.DELIVERED_AND_RECEIVED_CASH_ON_DELIVERY,
  ],
  [TrackStatus.AVAILABLE_FOR_PICKUP]: [TrackStatus.AVAILABLE_FOR_PICKUP],
  [TrackStatus.EXCEPTION]: [
    TrackStatus.EXCEPTION,
    TrackStatus.CUSTOMER_MOVED,
    TrackStatus.CUSTOMER_REFUSED_DELIVERY,
    TrackStatus.DELAYED_CUSTOMS_CLEARANCE,
    TrackStatus.DELAYED_EXTERNAL_FACTORS,
    TrackStatus.HELD_FOR_PAYMENT,
    TrackStatus.INCORRECT_ADDRESS,
    TrackStatus.PICK_UP_MISSED,
    TrackStatus.REJECTED_BY_CARRIER,
    TrackStatus.RETURNING_TO_SENDER,
    TrackStatus.RETURNED_TO_SENDER,
    TrackStatus.SHIPMENT_DAMAGED,
    TrackStatus.SHIPMENT_LOST,
  ],
  [TrackStatus.EXPIRED]: [TrackStatus.EXPIRED],
  [TrackStatus.PENDING]: [TrackStatus.PENDING, TrackStatus.OTHER, TrackStatus.CARRIER_ACCOUNT_NOT_CONNECTED],
};

export const TrackStatusLabel: any = {
  [TrackStatus.INFORMATION_RECEIVED]: 'Information Received',
  [TrackStatus.IN_TRANSIT]: 'In Transit',
  [TrackStatus.ACCEPTANCE_SCAN]: 'Acceptance scan',
  [TrackStatus.ARRIVAL_SCAN]: 'Arrival scan',
  [TrackStatus.ARRIVED_AT_DESTINATION_COUNTRY]: 'Arrived at destination country',
  [TrackStatus.CUSTOMS_CLEARANCE_COMPLETED]: 'Customs clearance completed',
  [TrackStatus.CUSTOMS_CLEARANCE_STARTED]: 'Customs clearance started',
  [TrackStatus.DEPARTURE_SCAN]: 'Departure Scan',
  [TrackStatus.PROBLEM_RESOLVED]: 'Problem resolved',
  [TrackStatus.FORWARDED_TO_A_DIFFERENT_DELIVERY]: 'Forwarded to a different delivery',
  [TrackStatus.OUT_FOR_DELIVERY]: 'Out for delivery',
  [TrackStatus.CUSTOMER_CONTACTED]: 'Customer contacted',
  [TrackStatus.DELIVERY_APPOINTMENT_SCHEDULE]: 'Delivery appointment scheduled',
  [TrackStatus.FAILED_ATTEMPT]: 'Failed attempt',
  [TrackStatus.ADDRESS_SEE_NOT_AVAILABLE]: 'Addreesee not available',
  [TrackStatus.BUSINESS_CLOSED]: 'Business Closed',
  [TrackStatus.DELIVERED]: 'Delivered',
  [TrackStatus.PICKED_UP_BY_CUSTOMER]: 'Picked up by customer',
  [TrackStatus.SIGN_BY_CUSTOMER]: 'Sign by customer',
  [TrackStatus.DELIVERED_AND_RECEIVED_CASH_ON_DELIVERY]: 'Delivered and received cash on delivery',
  [TrackStatus.AVAILABLE_FOR_PICKUP]: 'Available For Pickup',
  [TrackStatus.EXCEPTION]: 'Exception',
  [TrackStatus.CUSTOMER_MOVED]: 'Customer moved',
  [TrackStatus.CUSTOMER_REFUSED_DELIVERY]: 'Customer refused delivery',
  [TrackStatus.DELAYED_CUSTOMS_CLEARANCE]: 'Delayed (Customs clearance)',
  [TrackStatus.DELAYED_EXTERNAL_FACTORS]: 'Delayed (External factors)',
  [TrackStatus.HELD_FOR_PAYMENT]: 'Held for payment',
  [TrackStatus.INCORRECT_ADDRESS]: 'Incorrect Address',
  [TrackStatus.PICK_UP_MISSED]: 'Pick up missed',
  [TrackStatus.REJECTED_BY_CARRIER]: 'Rejected by carrier',
  [TrackStatus.RETURNING_TO_SENDER]: 'Returning to sender',
  [TrackStatus.RETURNED_TO_SENDER]: 'Returned to sender',
  [TrackStatus.SHIPMENT_DAMAGED]: 'Shipment damaged',
  [TrackStatus.SHIPMENT_LOST]: 'Shipment lost',
  [TrackStatus.EXPIRED]: 'Expired',
  [TrackStatus.PENDING]: 'Pending',
  [TrackStatus.OTHER]: 'Other',
  [TrackStatus.CARRIER_ACCOUNT_NOT_CONNECTED]: 'Carrier account not connected',
};

export const TrackStatusOptions = Object.keys(TrackStatusChild).reduce((arr: any[], value) => {
  const childs = (TrackStatusChild as any)[value].reduce((res: any[], child: any) => {
    res.push({
      label: TrackStatusLabel[child],
      value: child,
    });
    return res;
  }, []);
  const op = {
    label: TrackStatusLabel[value],
    value,
    options: childs,
  };
  arr.push(op);
  return arr;
}, []);

export const OrderStatus: any = {
  INFORMATION_RECEIVED: {
    INFORMATION_RECEIVED: 'InformationReceived',
  },
  IN_TRANSIT: {
    IN_TRANSIT: 'InTransit',
    ACCEPTANCE_SCAN: 'Acceptance scan',
    ARRIVAL_SCAN: 'Arrival scan',
    ARRIVED_AT_DESTINATION_COUNTRY: 'Arrived at destination country',
    CUSTOMS_CLEARANCE_COMPLETED: 'Customs clearance completed',
    CUSTOMS_CLEARANCE_STARTED: 'Customs clearance started',
    DEPARTURE_SCAN: 'Departure Scan',
    PROBLEM_RESOLVED: 'Problem resolved',
    FORWARDED_TO_A_DIFFERENT_DELIVERY: 'Forwarded to a different delivery',
  },
  OUT_FOR_DELIVERY: {
    OUT_FOR_DELIVERY: 'Out for delivery',
    CUSTOMER_CONTACTED: 'Customer contacted',
    DELIVERY_APPOINTMENT_SCHEDULE: 'Delivery appointment scheduled',
  },
  FAILED_ATTEMPT: {
    FAILED_ATTEMPT: 'Failed attempt',
    ADDRESS_SEE_NOT_AVAILABLE: 'Address see not available',
    BUSINESS_CLOSED: 'Business Closed',
  },
  DELIVERED: {
    DELIVERED: 'Delivered',
    PICKED_UP_BY_CUSTOMER: 'Picked up by customer',
    SIGN_BY_CUSTOMER: 'Sign by customer',
    DELIVERED_AND_RECEIVED_CASH_ON_DELIVERY: 'Delivered and received cash on delivery',
  },
  AVAILABLE_FOR_PICKUP: { AVAILABLE_FOR_PICKUP: 'AvailableForPickup' },
  EXCEPTION: {
    EXCEPTION: 'Exception',
    CUSTOMER_MOVED: 'Customer moved',
    CUSTOMER_REFUSED_DELIVERY: 'Customer refused delivery',
    DELAYED_CUSTOMS_CLEARANCE: 'Delayed (Customs clearance)',
    DELAYED_EXTERNAL_FACTORS: 'Delayed (External factors)',
    HELD_FOR_PAYMENT: 'Held for payment',
    INCORRECT_ADDRESS: 'Incorrect Address',
    PICK_UP_MISSED: 'Pick up missed',
    REJECTED_BY_CARRIER: 'Rejected by carrier',
    RETURNING_TO_SENDER: 'Returning to sender',
    RETURNED_TO_SENDER: 'Returned to sender',
    SHIPMENT_DAMAGED: 'Shipment damaged',
    SHIPMENT_LOST: 'Shipment lost',
  },
  EXPIRED: { EXPIRED: 'Expired' },
  PENDING: {
    PENDING: 'Pending',
    OTHER: 'Other',
    CARRIER_ACCOUNT_NOT_CONNECTED: 'Carrier account not connected',
  },
};

export const CARRIERS = CarrierData;
export const COUNTRIES = CountriesData;
export const PURPOSES = Purposes;

export const AFTERSHIP_ENDPOINT = {
  STATS_EXCEPTIONS_BY_CARRIER: '/orders/exceptions-by-carrier',
  STATS_CONNECTION: '/healthy',
};
