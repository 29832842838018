import qs from 'qs';

import { ObjectInterface } from '@interfaces';

import { GLAMIRA_API_ENDPOINT, USE_API_MAGENTO_MOCK_DATA } from '@constants';
import { downloadFile } from '@helpers/file';
import { buildUrl } from '@helpers/url';

import { ApiClient, subscribeAuthorizationHeader } from './api';
import { withRefreshTokenAndRetry } from './auth.service';

const defaultSearchCriteria: any = {
  currentPage: 1,
  pageSize: 20,
};

function transformApiData(response: any) {
  if (response.status != 200 && response.data.message) {
    throw new Error('Request Failure: ' + response?.data?.message?.toString());
  }
  return response.data[0];
}

function transformAxiosData(response: any) {
  if (response.status != 200 && response.data.message) {
    throw new Error('Request Failure: ' + response?.data?.message?.toString());
  }
  return response.data;
}

function applyDefaultFilterParam(params: any, sortField: string | null = null, sortDirection: 'ASC' | 'DESC' = 'ASC') {
  if (!params['searchCriteria']) {
    params['searchCriteria'] = { ...defaultSearchCriteria };
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    Object.keys(defaultSearchCriteria).forEach((key) => {
      if (typeof params['searchCriteria'][key] == 'undefined' || params['searchCriteria'][key] === null) {
        params['searchCriteria'][key] = defaultSearchCriteria[key];
      }
    });
  }
  if (!params.searchCriteria.sortOrders) {
    params.searchCriteria.sortOrders = [];
  }
  let defaultSortIdx = -1;
  params.searchCriteria.sortOrders.forEach(({ field }: { field: string; direction: 'asc' | 'desc' | null | '' | undefined }, idx: number) => {
    const aliasField = field.split('.').pop();
    if (sortField && (field === sortField || aliasField === sortField)) {
      defaultSortIdx = idx;
    }
  });
  if (sortField) {
    const sortOrderDefault = {
      field: sortField,
      direction: sortDirection,
    };
    if (defaultSortIdx >= 0) {
      params.searchCriteria.sortOrders[defaultSortIdx] = { ...sortOrderDefault, ...params.searchCriteria.sortOrders[defaultSortIdx] };
    } else {
      params.searchCriteria.sortOrders.push(sortOrderDefault);
    }
  }
  return params;
}

export const api = new ApiClient(
  GLAMIRA_API_ENDPOINT,
  { 'User-Agent': process.env.REACT_APP_API_USER_AGENT ?? 'glamira-backoffice' },
  { ___store: 'glus', ...(process.env.NODE_ENV === `development` ? { XDEBUG_TRIGGER: 'xdebug' } : {}) },
);

api.subscribe((client) => {
  subscribeAuthorizationHeader(client, 'magento2');
  withRefreshTokenAndRetry(client);
});
class MagentoService {
  getAllStore() {
    return Promise.all([
      api.get('/store/storeViews').then(transformAxiosData),
      api.get('/store/storeGroups').then(transformAxiosData),
      api.get('/store/websites').then(transformAxiosData),
    ]).then(([stores, groups, websites]) => {
      const groupMap: any = groups.reduce((obj: any, item: any) => {
        obj[item.id] = item;
        return obj;
      }, {});
      const websiteMap: any = websites.reduce((obj: any, item: any) => {
        obj[item.id] = item;
        return obj;
      }, {});
      return stores.reduce((arr: any, item: any) => {
        item.store_group = groupMap[item.store_group_id];
        item.website = websiteMap[item.website_id];
        arr.push(item);
        return arr;
      }, []);
    });
  }
  getReturnDispatchers() {
    const url = '/return-manage/dispatch-locations';
    return api.get(url).then(transformAxiosData);
  }
  getDispatchers() {
    const url = '/shipout/dispatcher/location/all';
    // /shipout/dispatcher/locations?searchCriteria[current_page]=1&searchCriteria[filter_groups][0][filters][0][field]=dispatch_type&searchCriteria[filter_groups][0][filters][0][value]=2
    return api.get(url).then(transformAxiosData);
  }
  getCountries() {
    const url = '/return-manage/countries';
    return api
      .get(url)
      .then(transformAxiosData)
      .then((res: any[]) =>
        res.map((item: ObjectInterface) => {
          const value = Object.keys(item).pop();
          return {
            value,
            label: value ? item[value] : '',
          };
        }),
      );
  }
  getTypeReturnList() {
    const url = '/return-manage/types-return';
    return api.get(url).then(transformAxiosData);
  }
  getReturnItemStateList() {
    const url = '/return-manage/item-states';
    return api.get(url).then(transformAxiosData);
  }
  getReturnStatusList() {
    const url = '/return-manage/statuses';
    return api.get(url).then(transformAxiosData);
  }
  products(params?: any) {
    const query = qs.stringify(applyDefaultFilterParam(params || {}));
    const url = `/products/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  getDashboardReturns(params?: any) {
    const query = qs.stringify(params || {});
    const url = `/return-manage/dashboard/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  getDashboardReturnDetail(params: ObjectInterface = {}) {
    const query = qs.stringify(params || {});
    const url = `/return-manage/dashboard-detail/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }

  getDashboardShipment(params?: any) {
    const query = qs.stringify(params || {});
    const url = `/shipment-manage/dashboard/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  getShipmentDashboardOrderDetails(params?: any) {
    const query = qs.stringify(params || {});
    const url = `/shipment-manage/dashboard/detail-order/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }

  getShipmentDashboardProductionDetails(params?: any) {
    const query = qs.stringify(params || {});
    const url = `/shipment-manage/dashboard/detail-production/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }

  getShipmentDashboardDispatcherDetails(params?: any) {
    const query = qs.stringify(params || {});
    const url = `/shipment-manage/package/list/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }
  getShipmentDashboardDispatcherItemsDetail(params?: any) {
    const query = qs.stringify(params || {});
    const url = `/shipment-manage/package-item/list/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }
  /* ==== Shipments ==== */
  fetchShipmentAvaiableSentToDispatcher(barcode: string[]) {
    const url = buildUrl('/shipment-manage/item-arrived/bulk-barcode/', [], { barcode });
    return api.get(url).then(transformAxiosData);
  }

  shipmentShipoutPackageForm(ids: string[] | number[]) {
    const query = qs.stringify({ ids });
    const url = `/shipment-manage/package/add-form/${query ? '?' : ''}${query}`;

    return api.post(url, {}).then(transformAxiosData);
  }

  getShipoutPackage(id: string) {
    const url = buildUrl('/shipment-manage/package/:id', { id });
    return api.get(url).then(transformAxiosData);
  }

  getShipoutPackageItems(params: ObjectInterface = {}) {
    const url = buildUrl('/shipment-manage/package-items', {}, params);

    return api.get(url).then(transformAxiosData);
  }

  exportXlsx(params: any = {}, namespace = 'shipout_arrived_item_listing') {
    params['namespace'] = namespace;
    const query = qs.stringify(applyDefaultFilterParam(params || {}));
    const url = `/grid/export/xls/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }

  exportShipoutPackageItems(id: string | number) {
    const searchCriteria: any = {
      filterGroups: [
        {
          filters: [
            {
              field: 'shipout_package.package_id',
              value: id,
            },
          ],
        },
      ],
    };
    const url = buildUrl(
      '/shipment-manage/package-items/export',
      {},
      {
        searchCriteria: searchCriteria,
      },
    );

    return api.get(url).then(transformAxiosData);
  }

  saveShipmentShipoutPackageForm(data: ObjectInterface) {
    const url = `/shipment-manage/package`;
    return api.post(url, data).then(transformAxiosData);
  }

  fetchShipmentAvaiableArriveToDispatcher(barcode: string[]) {
    const url = buildUrl('/shipment-manage/shipout-item/bulk-barcode/', [], { barcode });
    return api.get(url).then(transformAxiosData);
  }
  confirmShipmentArriveToDispatcher(ids: string[] | number[]) {
    const url = '/shipment-manage/shipout-item/close';
    return api.post(url, { shipout_item_ids: ids });
  }
  fetchShipmentPendingShipment(params: any = {}) {
    const query = qs.stringify(applyDefaultFilterParam(params || {}, 'created_at', 'DESC'));
    const url = `/package_shipments/package_readyship/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }
  fetchShipmentPackagesWaitCarrierShip(params: any = {}) {
    const query = qs.stringify(applyDefaultFilterParam(params || {}, 'main_table.entity_id', 'DESC'));
    const url = `/package_shipments/wait-carrier-ship/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }
  fetchShipmentPackageOrderShipped(params: any = {}) {
    const query = qs.stringify(applyDefaultFilterParam(params || {}, 'main_table.entity_id', 'DESC'));
    const url = `/package_shipments/order-shipped/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }
  fetchShipmentPackages(params: any = {}) {
    const query = qs.stringify(applyDefaultFilterParam(params || {}, 'package_id', 'DESC'));
    const url = `/shipment-manage/package/list/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }
  createShipPackage(ids: (string | number)[]) {
    const query = qs.stringify({ packageIds: ids });
    const url = `/package_shipments/package_readyship/create-ship/${query ? '?' : ''}${query}`;
    return api.get(url);
  }
  fetchShipoutPackageFormData(ids: (string | number)[]) {
    const query = qs.stringify({ shipoutItemIds: ids });
    const url = `/shipment-manage/item-arrived/form-data/${query ? '?' : ''}${query}`;
    return api.get(url);
  }
  createShipPackageAndGenerateLabel(ids: (string | number)[]) {
    const query = qs.stringify({ packageIds: ids });
    const url = `/package_shipments/package_readyship/create-ship-generate-label/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  shipmentReadyShipMassPrint(ids: (string | number)[], type: string) {
    const query = qs.stringify({ packageIds: ids, type: type });
    const url = `/package_shipments/package_readyship/mass-action-print/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  shipmentReadyShipGenerateUpsXmlAndCreateShip(ids: (string | number)[], massAdditional: (string | number)[] = []) {
    const query = qs.stringify({ packageIds: ids, additional: massAdditional });
    const url = `/package_shipments/package_readyship/generate-ups-xml-and-create_ship/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  cancelShipment(ids: (string | number)[]) {
    const query = qs.stringify({ packageIds: ids });
    const url = `/package_shipments/wait-carrier-ship/cancel-shipment/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  cancelShipmentLostAndNewProduction(ids: (string | number)[]) {
    const query = qs.stringify({ packageIds: ids });
    const url = `/package_shipments/wait-carrier-ship/cancel-lost-shipment-and-new-production/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  shipmentWaitToCarrierMassPrint(ids: (string | number)[], type: string) {
    const query = qs.stringify({ packageIds: ids, type: type });
    const url = `/package_shipments/wait-carrier-ship/mass-action-print/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  shipmentWaitToCarrierMassGenerateUpsXml(ids: (string | number)[], type: string, massAdditional: (string | number)[] = []) {
    const query = qs.stringify({ packageIds: ids, type: type, additional: massAdditional });
    const url = `/package_shipments/wait-carrier-ship/mass-action-generate-ups-xml/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  shipmentWaitToCarrierImportTrackUpsXml(data: any[]) {
    const url = '/package_shipments/wait-carrier-ship/import-csv-track-ups-and-send-email';
    return api.post(url, data).then(transformAxiosData);
  }

  shipmentWaitToCarrierMassGenerateShippingLabel(ids: (string | number)[]) {
    const query = qs.stringify({ packageIds: ids });
    const url = `/package_shipments/wait-carrier-ship/mass-action-generate-shipping-label/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  refeshGridWaitToShip() {
    const url = '/package_shipments/wait-carrier-ship/refresh-grid';
    return api.get(url).then(transformAxiosData);
  }

  refeshGridOrderShipped() {
    const url = '/package_shipments/order-shipped/refresh-grid';
    return api.get(url).then(transformAxiosData);
  }

  fetchShippingServiceDetail(id: string | number) {
    const url = buildUrl(`/shipping_service/shipment/detail/:id`, { id });
    return api.get(url).then(transformAxiosData);
  }

  pullShipmentInfoFromCarrier(id: string | number) {
    const url = buildUrl(`/shipping_service/shipment/pull-tracking-info/:id`, { id });
    return api.get(url).then(transformAxiosData);
  }

  async updateShipmentStatus(id: string | number, attrs: ObjectInterface) {
    const url = buildUrl(`/shipping_service/shipment/update-status/:id`, { id });
    return api.put(url, attrs).then(transformApiData);
  }

  /* ==== Return Api ==== */
  getManageStatusLabel(
    type: string[] = [
      'order_status',
      'item_state',
      'awaiting_status',
      'refund_status',
      'courier_status',
      'shipment_type',
      'shipment_status',
      'shipout_item_status',
      'item_metal_labels',
      'location_factory',
      'manufacturer_labels',
      'carrier_code_labels',
    ],
  ) {
    const query = qs.stringify({ type });
    const url = `/label-status-manage/list/${query ? '?' : ''}${query}`;
    return api
      .get(url)
      .then(transformAxiosData)
      .then((items: any[]) => {
        return items.reduce((obj: any, { type, options }) => {
          obj[type] = options;
          return obj;
        }, {});
      })
      .then((data) => {
        return data;
      });
  }
  getReturnStats(params?: any) {
    const query = qs.stringify(params || {});
    const url = `/return-manage/dashboard/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformApiData);
  }

  getReturnOrderStats(params: any = {}) {
    return this.getReturnStats({ ...params, type: 'after_sales_processing' });
  }

  getReturnDispatcherStats(params: any = {}) {
    return this.getReturnStats({ ...params, type: 'dispatcher' });
  }

  getReturnServiceWarrantyStats(params: any = {}) {
    return this.getReturnStats({ ...params, type: 'service_warranty' });
  }

  getReturnFinanceTransactionStats(params: any = {}) {
    return this.getReturnStats({ ...params, type: 'finance_transaction' });
  }

  getReturnJourneyStats(params: any = {}) {
    return this.getReturnStats({ ...params, type: 'return_journey' });
  }

  getRepairJourneyStats(params: any = {}) {
    return this.getReturnStats({ ...params, type: 'repair_journey' });
  }
  // process items
  getReturnItems(params: any = {}) {
    const query = qs.stringify(applyDefaultFilterParam(params || {}, 'created_at', 'DESC'));
    const url = `/return-manage/request-items/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }

  updateCustomerInfo(id: string | number, data: ObjectInterface = {}) {
    const url = buildUrl('/return-manage/customer-info/:id', { id });
    return api.put(url, data);
  }

  getReturnByBarcode(barcode: string) {
    const url = buildUrl('/return-manage/request-item/:barcode/validate', { barcode });
    return api.get(url).then(transformAxiosData);
  }

  getValidateReturn(barcode: string, nextStep: any) {
    const query = qs.stringify({
      next_step: nextStep,
    });
    const url = `/return-manage/request-item/${barcode}/validate${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }

  fetchReturnSentToHQ(barcode: string[]) {
    const url = buildUrl('/return-manage/return-shipout/dispatcher/bulk-barcode', [], { barcode });
    return api.get(url).then(transformAxiosData);
  }

  returnShipoutPackageForm(ids: string[] | number[]) {
    const url = '/return-manage/return-shipout/package-form';
    return api.post(url, { ids }).then(transformAxiosData);
  }

  saveShipoutPackageForm(data: ObjectInterface) {
    const url = '/return-manage/return-shipout/package/add';
    return api.post(url, data).then(transformApiData);
  }

  fetchReturnArrivedToHQ(barcode: string[]) {
    const url = buildUrl('/return-manage/return-shipout/package-item/onway-to-hq/bulk-barcode', [], { barcode });
    return api.get(url).then(transformAxiosData);
  }

  confirmReturnArrivedToHQ(ids: string[] | number[]) {
    const url = '/return-manage/return-shipout/package-item/confirm-arrived-hq';
    return api.post(url, { ids }).then(transformApiData);
  }

  fetchReturnProcessAtHQ(barcode: string[]) {
    const url = buildUrl('/return-manage/finish-process-at-hq/bulk-barcode', [], { barcode });
    return api.get(url).then(transformAxiosData);
  }

  confirmSendToDispatcher(ids: string[] | number[]) {
    const url = '/return-manage/send-to-dispatcher';
    return api.post(url, { ids }).then(transformAxiosData);
  }

  exportRequestItemPdf(id: string | number, forceDownload = false) {
    const url = `/return-manage/request-item/${id}/export-pdf`;
    return api.get(url).then((res) => {
      if (res.data) {
        const data = {
          base64_encoded_data: res.data,
          type: 'application/pdf',
          name: 'return-request-item.pdf',
        };
        if (forceDownload) {
          downloadFile(data);
        }
        return data;
      } else {
        throw new Error("Can't export pdf");
      }
    });
  }

  getReturnEdit(id: string | number, _requestId: string | number | null) {
    const url = buildUrl('/return-manage/request-item/edit/:id', { id });
    // requestId && api.get(buildUrl('/return-manage/request/edit/:id', { id: requestId })).then(transformAxiosData);
    return api.get(url).then(transformAxiosData);
  }
  updateReturnRequest(data: ObjectInterface = {}) {
    const url = buildUrl('/return-manage/request-item', data, {});
    return api.put(url, data);
  }

  addStaffComment(data: ObjectInterface | { requestItemId: number | string; requestId: number | string; commentStaff: string }) {
    const url = buildUrl('/return-manage/request-item/comment-staff', {}, data);
    return api.post(url, data).then(transformAxiosData);
  }

  saveConfuseRingSize(data: ObjectInterface & { requestItemId: number | string; requestId: number | string; confusingRingSize: number | string }) {
    const url = buildUrl('/return-manage/request-item/confusing-ring-size', {}, data);
    return api.post(url, data).then(transformAxiosData);
  }

  returnRevertStoreCredit(data: ObjectInterface, conf: ObjectInterface = {}) {
    const url = buildUrl('/return-manage/revert-store-credit', {}, {});
    return api.post(url, data, conf).then(transformApiData);
  }

  fetchReturnProcessItem(barcode: string) {
    const url = buildUrl('/return-manage/process-item', {}, { barcode });
    return api.get(url);
  }

  updateReturnProcessItem(barcode: string, data: ObjectInterface) {
    const url = buildUrl('/return-manage/process-item', {}, { barcode });
    return api.post(url, data).then(transformApiData);
  }

  saveReturnRequest(data: ObjectInterface) {
    const url = buildUrl('/return-manage/request-item');
    return api.put(url, data);
  }

  saveReturnCourier(data: ObjectInterface, conf: ObjectInterface = {}) {
    const url = buildUrl('/return-manage/courier');
    return api.post(url, data, conf).then(transformApiData);
  }

  getReturnCourierLabel(data: ObjectInterface = {}, conf: ObjectInterface = {}) {
    const url = buildUrl('/return-manage/print-courier-label');
    return api.post(url, data, conf).then(transformAxiosData);
  }

  getReturnCourierInvoiceLabel(data: ObjectInterface = {}, conf: ObjectInterface = {}) {
    const url = buildUrl('/return-manage/print-courier-invoice-label');
    return api.post(url, data, conf).then(transformAxiosData);
  }

  getUserList(data: ObjectInterface = {}) {
    const url = buildUrl('/users/list-as-options', [], data);
    return api.get(url).then(transformAxiosData);
  }

  getTicketConfig(data: ObjectInterface = {}) {
    const url = buildUrl('/freshdeskticket/ticket/get-config', [], data);
    return api.get(url).then(transformAxiosData);
  }

  /* REPORTS */
  getLateItemState(data: ObjectInterface = {}, options: ObjectInterface = {}) {
    const url = buildUrl('/logistic/late/states', [], data);
    return api.get(url, options).then(transformAxiosData);
  }
  getStatsLateItemStatus(data: ObjectInterface = {}, options: ObjectInterface = {}) {
    const { type, ...params } = data;
    const url = buildUrl('/logistic/late/stats/:type', { type }, params);
    return api.get(url, options).then(transformAxiosData);
  }
  getLatestOrder(data: ObjectInterface = {}, options: ObjectInterface = {}) {
    const url = buildUrl('/logistic/late/items', [], data);
    return api.get(url, options).then(transformAxiosData);
  }

  refreshLateItem(data: ObjectInterface = {}, options: ObjectInterface = {}) {
    const url = buildUrl('/logistic/late/refresh');
    return api.post(url, data, options).then(transformAxiosData);
  }

  getShipmentOverdueDelivery(data: ObjectInterface = {}, options: ObjectInterface = {}) {
    const url = buildUrl('/shipment-report/overdue-delivery-date', [], data);
    return api.get(url, options).then(transformAxiosData);
  }

  getMembers(data: ObjectInterface = {}, options: ObjectInterface = {}) {
    const url = buildUrl('/return-manage/team-members', {}, data);
    return api
      .get(url, options)
      .then(transformAxiosData)
      .then((items) => {
        return {
          items,
          total_count: items.length,
          search_criteria: {
            page_size: items.length,
            current_page: 1,
          },
        };
      });
  }

  getListShipoutWaiting(params: any = {}) {
    const query = qs.stringify(applyDefaultFilterParam(params || {}, 'delivery_date', 'DESC'));
    const url = `/shipment-manage/item-arrived/list/${query ? '?' : ''}${query}`;
    return api.get(url).then(transformAxiosData);
  }

  updateSupportTicket(params: any = {}, oldItemData: any = {}) {
    const url = buildUrl('/freshdeskticket/ticket/add', {}, {});
    const data: ObjectInterface = {
      ticket: oldItemData ? { ...oldItemData } : {},
      data: { ...params },
    };
    return api.post(url, data);
  }

  returnItemsMassPrint(ids: (string | number)[], type: string) {
    const query = qs.stringify({ returnIds: ids, type: type });
    const url = `/return-manage/request-item/mass-action-print/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  massActionReturnUpdateItemState(ids: (string | number)[], additional: string | number) {
    const query = qs.stringify({ returnIds: ids, itemState: additional });
    const url = `/return-manage/request-item/mass-action-update-item-state/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  massActionReturnUpdateAwaitingStatus(ids: (string | number)[], additional: string | number) {
    const query = qs.stringify({ returnIds: ids, awaitingStatus: additional });
    const url = `/return-manage/request-item/mass-action-update-awaiting-status/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  massActionReturnUpdateStatus(ids: (string | number)[], additional: string | number) {
    const query = qs.stringify({ returnIds: ids, status: additional });
    const url = `/return-manage/request-item/mass-action-update-return-status/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  massActionMarkLostAndNewProduce(ids: (string | number)[]) {
    const query = qs.stringify({ returnIds: ids });
    const url = `/return-manage/request-item/mass-lost-and-new-produce/${query ? '?' : ''}${query}`;
    return api.get(url);
  }

  returnPrintBarcodePdf(barcodes: string[]) {
    const query = qs.stringify({ barcodes: barcodes });
    const url = `/return-manage/request-item/print-barcode-pdf/${query ? '?' : ''}${query}`;
    return api.get(url);
  }
}

// allow override default and mock data
class MagentoServiceMock extends MagentoService {}

export default USE_API_MAGENTO_MOCK_DATA ? new MagentoServiceMock() : new MagentoService();
